import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './reroll.scss';
import { BCMCharacter } from '../../../modules/bcm/common/components/bcm-character';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const BCMGuidesRerollPage: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page play-on-pc-page bcm bcm-reroll'}
      game="bcm"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/blackclover/categories/cat_reroll.png"
            alt="Reroll"
          />
        </div>
        <div className="page-details">
          <h1>Black Clover Mobile Reroll</h1>
          <h2>How to reroll efficiently in Black Clover Mobile.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <Alert variant="primary alert-red">
          <p>
            The guide has been updated on <strong>30.11.2023</strong> to include
            Global release details - the Delete Account button has been added
            back in the Global version!
          </p>
        </Alert>
        <p>
          The process of rerolling in Black Clover Mobile{' '}
          <strong>changes depending on the version you play</strong>. While the
          KR and JPN servers make the reroll process as painless as possible,{' '}
          <strong className="red">
            the Canada server did the opposite - it made it as tedious as
            possible
          </strong>
          . As for the upcoming Global server, we're assuming it will follow the
          Canada tedious route of rerolling, because the Global server will be
          another server added in the Canada client. This means that if you want
          to start strong in the game, you have to prepare for pain. A lot of
          pain.
        </p>
        <SectionHeader title="Rerolls in different servers" />
        <Row xs={1} xl={2} xxl={2} className="reroll-paths">
          <Col>
            <div className="box">
              <div className="option easy"></div>
              <div className="inside">
                <p className="time">
                  Time needed: <strong>7/10 minutes per reroll</strong>
                </p>
                <p className="time">
                  Can reroll on: <strong>Phone or Emulator</strong>
                </p>
                <p className="time">
                  Can delete account: <strong>Yes</strong>
                </p>
                <p>
                  Rerolling on the KR/JPN server is super easy as you just need
                  to clear the tutorial and unlock the summoning system - all
                  the pulls you need to do your reroll will wait for you in the
                  mail. Then after doing your pulls, you can simply delete your
                  account using an option available in-game and try again!
                </p>
                <hr />
                <h6>Process</h6>
                <ol>
                  <li>Start the game and login as Guest,</li>
                  <li>
                    Play through the tutorial - you will quickly unlock the
                    Quest Tracker feature and it will auto-path you to the next
                    NPC to talk to,
                  </li>
                  <ul>
                    <li>
                      You can skip most of the cutscenes - there will be a skip
                      button in top right corner of the screen when it's
                      possible to do,
                    </li>
                  </ul>
                  <li>
                    Once you defeat the Bear stage in the forest and return to
                    the Town, you will unlock Summoning. Check your mail for the
                    currently available starter rewards and do your pulls!
                  </li>
                  <li>
                    After pulling, if you're not happy with the results, simply
                    head to the Settings (Account Tab) where you will find
                    Account Delete option. It will instantly take you to the
                    starter screen and allow you to start from scratch.
                  </li>
                </ol>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box">
              <div className="option hard"></div>
              <div className="inside">
                <p className="time">
                  Time needed: <strong>50/60 minutes per reroll</strong>
                </p>
                <p className="time">
                  Can reroll on: <strong>Both Emulator and Phone</strong>
                </p>
                <p className="time">
                  Can delete account: <strong>Yes!</strong>
                </p>
                <p>
                  Rerolling on the Global server was changed to be as tedious as
                  possible, but thankfully they brought the Delete Account
                  button back! This means that you don't need to worry about
                  clearing cache or creating multiple Google Accounts. Once you
                  finish the nearly hour long reroll, you can just delete your
                  guest account from the game.
                </p>
                <hr />
                <h6>Process</h6>
                <ol>
                  <li>Start the game and login as Guest,</li>
                  <li>
                    Play through the tutorial - you will quickly unlock the
                    Quest Tracker feature and it will auto-path you to the next
                    NPC to talk to,
                  </li>
                  <ul>
                    <li>
                      You can skip most of the cutscenes - there will be a skip
                      button in top right corner of the screen when it's
                      possible to do,
                    </li>
                  </ul>
                  <li>
                    Once you defeat the Bear stage in the forest and return to
                    the Town, you will unlock Summoning. Then you will be able
                    to do a tutorial 10-pull at this point which{' '}
                    <strong>is fixed and always gives Charlotte</strong>. The
                    game will also give you 10 summon tickets in the mail,
                  </li>
                  <li>
                    Continue playing through the story and once you clear
                    Chapter 1 Episode 5 you will{' '}
                    <strong>obtain the pre-register rewards</strong> (which will
                    contain 20 pulls and some other goodies),
                  </li>
                  <ul>
                    <li>
                      It should take you roughly 30 minutes to reach this point.
                    </li>
                  </ul>
                  <li>
                    Continue playing through the story and once you clear
                    Chapter 1 Episode 11 you will{' '}
                    <strong>unlock the 'Infinite Reroll Banner'</strong>. You
                    will be able to do twenty 10-pulls and keep one of them,
                  </li>
                  <ul>
                    <li>
                      It should take you roughly 50 minutes to reach this point.
                    </li>
                  </ul>
                  <li>
                    You're done! If you got who you want, you can continue
                    playing. If you didn't - use the Delete Account feature
                    found in the Settings to start again.
                  </li>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="How to reroll (Canada & Global)" />
        <h5>Reroll on your phone</h5>
        <p>
          The Delete Account button is back in Global, so you can reroll on your
          phone if you want now!
        </p>
        <h5>Reroll on an emulator</h5>
        <p>
          This option is the one we suggest using. Depending on how strong is
          your PC you will either reroll using a single instance or using the
          multi-instance method.
        </p>
        <SectionHeader title="Videos" />
        <p>
          If you want to check how the Canada/Global reroll process works in
          'action', here are some handy videos:
        </p>
        <Row xs={1} xl={2} className="video-row">
          <Col>
            <YoutubeEmbed embedId="SClWN_MPy8U" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="W11KKNjdTdI" />
          </Col>
        </Row>
        <SectionHeader title="Available banners (Global)" />
        <h5>Seasonal banner (Season 1)</h5>
        <p>On this banner you can get the 3 seasonal characters:</p>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="asta-clover-academy" enablePopover />
          <BCMCharacter
            mode="icon"
            slug="mimosa-vermillion-clover-academy"
            enablePopover
          />
          <BCMCharacter
            mode="icon"
            slug="yami-sukehiro-clover-academy"
            enablePopover
          />
        </div>
        <p>
          All of them are really strong in various areas of the game and
          specialize in different things:
        </p>
        <ul>
          <li>
            <strong>Asta</strong> - a single-target focused DPS who is great
            both in PVE and PVP. He can buff himself and gain an extra turn when
            attacking targets who are above 80% HP. This makes him a great
            damage dealer for any content who is also considered as the best
            damage dealer for PVP in KR/JPN (mainly due to his burst damage and
            high Speed),
          </li>
          <li>
            <strong>Mimosa</strong> - a Healer great both in PVE and PVP. While
            early she will be the best option when you need healing, in the
            future a lot stronger healers (or shielders) will release that will
            slightly powercreep her (but she still remains close to the top),
          </li>
          <li>
            <strong>Yami (Blue)</strong> - a hybrid DPS/debuffer. While his
            damage is pretty decent, the debuffs he brings will make him a
            nightmare in the PVP, but in the KR/JPN version after the first few
            weeks, the meta shifted away from Blue team and Blue Yami's usage
            took a dip.
          </li>
        </ul>
        <h5>Yami (Red) banner</h5>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="yami-sukehiro" enablePopover />
        </div>
        <p>
          Yami (Red) will be the prime Damage Dealer that will carry you through
          the early story and PVP. Till Summer Noelle release (which happens in
          Season 2, roughly 6 weeks after the game launches) he will be
          considered as the best option for the DPS Slot, but then he will start
          to fall off.
        </p>
        <SectionHeader title="What banner to pull on?" />
        <p>
          Considering the fact that you will be able to obtain a Seasonal
          character of your choice via the Destiny Gate system,{' '}
          <strong>we suggest not rolling on that banner </strong>- at least
          during the reroll process. That's because some of the standard pool
          characters are among the strongest in the game (yes, even now in
          KR/JP) and rerolling for them will future-proof your account. Once you
          finish the reroll process and secure a great start, you can go back to
          the Seasonal banner.
        </p>
        <h6>Which Season 1 character should you pick?</h6>
        <ul>
          <li>
            Short term (first few weeks): Asta &gt; Blue Yami &gt; Mimosa,
          </li>
          <ul>
            <li>
              Mimosa is last because you don't need a healer in the early game
              at all - neither in PVP or PVE,
            </li>
          </ul>
          <li>Long term: Asta &gt; Mimosa &gt; Blue Yami</li>
          <ul>
            <li>
              Blue Yami won't be meta anymore in PVP as there will be
              replacements for him. Mimosa will actually be used more in PVP
              then (because she is very gear reliant) and you will be farming
              harder PVE content where she shines more.
            </li>
          </ul>
        </ul>
        <p>
          Overall Season 1 Mimosa is the best among the bunch - both short and
          long term, but the issue is that you really want a DPS more than a
          healer in the early game, so Asta will actually help you more.
        </p>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="asta-clover-academy" enablePopover />
        </div>
        <p>
          As for the non-seasonal characters, getting Lotus + Mars is the way to
          go as they're very strong on release and still top tier even now in
          KR/JPN versions - and they're also core members of the Red Gear
          dungeon team on top of being monsters in PVP:
        </p>
        <p>
          If you have to pick between the two,{' '}
          <strong>
            Lotus has a priority - Mars can be replaced by other units in the
            Gear Dungeon, but Lotus has no replacement.
          </strong>
        </p>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="lotus-whomalt" enablePopover />
          <BCMCharacter mode="icon" slug="mars" enablePopover />
        </div>
        <SectionHeader title="Reroll banner priority" />
        <p>
          Now that you're satisfied with your ticket pulls, it's time to go back
          to the Reroll banner! Here's the priority of the characters that you
          want to get from there:
        </p>
        <Table striped bordered responsive className="banner-history">
          <thead>
            <tr>
              <th>Priority</th>
              <th>Characters</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon icon={faStar} width="18" />
                <FontAwesomeIcon icon={faStar} width="18" />
                <FontAwesomeIcon icon={faStar} width="18" />
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="lotus-whomalt"
                    enablePopover
                  />
                  <BCMCharacter mode="icon" slug="mars" enablePopover />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faStar} width="18" />
                <FontAwesomeIcon icon={faStar} width="18" />
                <FontAwesomeIcon icon={faStarEmpty} width="18" />
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="william-vangeance"
                    enablePopover
                  />

                  <BCMCharacter
                    mode="icon"
                    slug="rades-spirito"
                    enablePopover
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faStar} width="18" />
                <FontAwesomeIcon icon={faStarEmpty} width="18" />
                <FontAwesomeIcon icon={faStarEmpty} width="18" />
              </td>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="rill-boismortier"
                    enablePopover
                  />
                  <BCMCharacter
                    mode="icon"
                    slug="jack-the-ripper"
                    enablePopover
                  />
                  <BCMCharacter
                    mode="icon"
                    slug="leopold-vermillion"
                    enablePopover
                  />
                  <BCMCharacter mode="icon" slug="nozel-silva" enablePopover />
                  <BCMCharacter mode="icon" slug="sally" enablePopover />
                  <BCMCharacter
                    mode="icon"
                    slug="charlotte-roselei"
                    enablePopover
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          Charlotte has been moved to the lowest priority tier because everyone
          gets her for free from first 10-pull in Global.
        </p>
        <p>
          And here's a bit information about some of the stronger characters:
        </p>
        <Table striped bordered responsive className="banner-history">
          <thead>
            <tr>
              <th>Character</th>
              <th>Priority</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="lotus-whomalt"
                    enablePopover
                  />
                </div>
              </td>
              <td>Lotus Whomalt</td>
              <td>
                Hands down the best debuffer in the game who holds that title
                even now in KR/JPN versions of the game. He is a staple of most
                PVP teams and also makes farming the highest Red Gear dungeon a
                breeze.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter mode="icon" slug="mars" enablePopover />
                </div>
              </td>
              <td>Mars</td>
              <td>
                The best tank in the game. While other positions got powercrept
                a bit after the release, Mars is holding strong. You will see
                him in most PVP teams during the first month and he's also one
                of the suggested characters for the Red Gear dungeon (but while
                Lotus can't be replaced in that team, Mars has a lower rarity
                alternative - that's why he's lower on the reroll priority
                list).
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="william-vangeance"
                    enablePopover
                  />
                </div>
              </td>
              <td>William Vangeance</td>
              <td>
                A very strong offensive and defensive buffer who kept that spot
                for a very long time and even when more buffers were added to
                the game, he still is a great pick - mainly due to his Counter
                that will be very annoying to deal with in the early PVP meta.
                Without him you will suffer in the early PVP and PVE content as
                there's no other character that can replace what he brings to
                the table.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container for-nikke">
                  <BCMCharacter
                    mode="icon"
                    slug="rades-spirito"
                    enablePopover
                  />
                </div>
              </td>
              <td>Rades Spirito</td>
              <td>
                A Shielder who will be the best character at this role till
                Summer Noelle release (and even still used after that) - he will
                be part of the early PVP turtle teams and also used in Blue Gear
                dungeon.
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default BCMGuidesRerollPage;

export const Head: React.FC = () => (
  <Seo
    title="Reroll | Black Clover M | Prydwen Institute"
    description="How to reroll efficiently in Black Clover Mobile Global, KR and JPN!"
    game="bcm"
  />
);
